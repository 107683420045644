import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import BodyClassName from 'react-body-classname'
import './Splash.scss'

export default function Splash({ heading, children, className }) {

    return (
        <BodyClassName className={`full-screen ${className || ''}`}>
            <div className="splash">
                <Navbar className="splash__navbar" variant="transparent" />

                <div className="splash__container">
                    {heading}

                    {children}
                </div>

                <Footer className="splash__footer" />
            </div>
        </BodyClassName>
    )
}