import React, { useState, useCallback, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  useRouteMatch,
} from "react-router-dom"
import { ThemeContext, Theme } from './contexts/theme'
import { ConfigContext } from './contexts/config'
import { AuthContext } from './contexts/auth'
import BodyClassName from 'react-body-classname'
import { Provider as HttpProvider } from "use-http"
import Home from './pages/Home'
import Article from './pages/Article'
import Promoted from './pages/Promoted'
import Popular from './pages/Popular'
import Saved from './pages/Saved'
import Register from './pages/Register'
import Login from './pages/Login'
import ResetPassword from './pages/ResetPassword'
import VerifyUser from './pages/VerifyUser'
import VerifyPasswordReset from './pages/VerifyPasswordReset'
import NotFound from './pages/NotFound'
import ScrollToTop from './components/ScrollToTop'
import './App.scss'
import initialConfig from './config'

let logoutTimer

function App() {
  const [config, setConfig] = useState(initialConfig)

  const [theme, setTheme] = useState(Theme.Light)

  const toggleTheme = () => {
    let newTheme = Theme.Light
    if (theme === Theme.Light) newTheme = Theme.Dark

    setTheme(newTheme)
    localStorage.setItem("theme", newTheme)
  }

  useEffect(() => {
    const storedTheme = localStorage.getItem("theme")
    if (storedTheme) {
      console.log("storedTheme", storedTheme)
      setTheme(storedTheme)
    }
  }, [])

  const [token, setToken] = useState(false)
  const [tokenExpirationDate, setTokenExpirationDate] = useState()

  const login = useCallback((token, expirationTime) => {
    setToken(token)
    let expiration

    if (expirationTime instanceof Date) {
      expiration = expirationTime
    } else {
      expirationTime = expirationTime || (60 * 30)
      expiration = new Date(new Date().getTime() + (expirationTime * 1000))
    }

    setTokenExpirationDate(expiration)
    localStorage.setItem(
      "userData",
      JSON.stringify({
        token,
        expirationTime: expiration.toISOString()
      })
    )
  }, [])

  const logout = useCallback(() => {
    setToken(null)
    localStorage.removeItem("userData")
  }, [])

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"))
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expirationTime) > new Date()
    ) {
      login(storedData.token, new Date(storedData.expirationTime))
    }
  }, [login])

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime = tokenExpirationDate.getTime() - new Date().getTime()
      logoutTimer = setTimeout(logout, remainingTime)
    } else {
      clearTimeout(logoutTimer)
    }
  }, [token, logout, tokenExpirationDate])

  return (
    <ConfigContext.Provider
      value={{
        config,
        setConfig
      }}
    >
      <AuthContext.Provider
        value={{
          isLoggedIn: !!token,
          token: token,
          login: login,
          logout: logout
        }}
      >
        <HttpProvider
          url={config.API.BASE_URL}
          key={token}
          options={{
            headers: {
              Accept: 'application/json',
              ...(token ? { Authorization: `Bearer ${token}` } : {}),
            },
          }}
        >
          <ThemeContext.Provider value={{ theme, toggleTheme }}>
            <BodyClassName className={`${theme}`}>
              <div className="app" id="app">
                <Switch>
                  <Route path="/articles/:id">
                    <Article />
                  </Route>
                  <Route exact path="/promoted">
                    <Promoted />
                  </Route>
                  <Route exact path="/popular">
                    <Popular />
                  </Route>
                  <Route exact path="/saved">
                    <Saved />
                  </Route>
                  <Route exact path="/register">
                    <Register />
                  </Route>
                  <Route exact path="/login">
                    <Login />
                  </Route>
                  <Route exact path="/reset-password">
                    <ResetPassword />
                  </Route>
                  <Route exact path="/verify-password-reset">
                    <VerifyPasswordReset />
                  </Route>
                  <Router exact path="/verify-user">
                    <VerifyUser />
                  </Router>
                  <Route exact path="/">
                    <Home />
                  </Route>
                  <Route path="*">
                    <NotFound />
                  </Route>
                </Switch>
                <ScrollToTop scrollStepInPx="250" delayInMs="15" />
              </div>
            </BodyClassName>
          </ThemeContext.Provider>
        </HttpProvider>
      </AuthContext.Provider>
    </ConfigContext.Provider>
  )
}

export default App
