import React from 'react'
import { Link } from "react-router-dom"
import './Footer.scss'

export default function Footer({ className }) {
    return (
        <footer className={`footer page__footer is-themed ${className || ''}`}>
            <div className="container">
                <div className="footer__wrapper">
                    <div className="footer__section footer__section--left">
                        Copyright © Gamefeed
                    </div>
                    <div className="footer__section footer__section--right">
                        <nav className="footer__nav">
                            <ul>
                                <li>
                                    <a href="#">Terms & Conditions</a>
                                </li>

                                <li>
                                    <Link to="/privacy-policy">Privacy Policy</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </footer>
    )
}