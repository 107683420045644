
import * as firebase from "firebase"

const firebaseApp = firebase.initializeApp({
  // Production
  apiKey: "AIzaSyDXNJ-UN4Sq2378sh_YmLqeXkR1OMqne1Y",
  authDomain: "gamefeed-164214.firebaseapp.com",
  databaseURL: "https://gamefeed-164214.firebaseio.com",
  projectId: "gamefeed-164214",
  storageBucket: "gamefeed-164214.appspot.com",
  messagingSenderId: "388481295475",
  appId: "1:388481295475:web:e02db15a0d8ce643903606",
  measurementId: "G-EZHXLVWDFZ",

  // Staging
  // apiKey: "AIzaSyD5JGJdvi9so58QdHTxL9dIg3aAFO2cuqs",
  // authDomain: "game-feed-staging.firebaseapp.com",
  // databaseURL: "https://game-feed-staging.firebaseio.com/",
  // projectId: "game-feed-staging",
  // storageBucket: "game-feed-staging.appspot.com",
  // messagingSenderId: "444466616870"
})

const loginUsingExternalProvider = async (providerName, { authContext, config, loginUsingProvider, setSubmitting, setFieldError, onSuccess }) => {
    const firebaseAuth = firebaseApp.auth()
    let provider
    let token
    
    switch (providerName) {
        case "facebook":
            provider = new firebase.auth.FacebookAuthProvider();
            break;
        case "google-oauth2":
            provider = new firebase.auth.GoogleAuthProvider();
            break;
        case "apple-id":
            provider = new firebase.auth.OAuthProvider("apple.com");
            provider.addScope("email");
            provider.addScope("name");
            break;
    }
    const user = await firebaseAuth.signInWithPopup(provider)

    switch(providerName) {
        case "facebook":
        case "google-oauth2":
            token = user.credential.accessToken;
            break;
        case "apple-id":
            token = user.credential.idToken;
            break;
    }
    
    setSubmitting(true)

    const data = await loginUsingProvider.post({
        client_id: config.API.CLIENT_ID,
        client_secret: config.API.CLIENT_SECRET,
        grant_type: "convert_token",
        backend: providerName,
        token,
    })

    if (!loginUsingProvider.response.ok) {
        setFieldError('_', [data.error_description])
        setSubmitting(false)
    } else {
        authContext.login(data.access_token, data.expires_in)
        onSuccess && onSuccess(data)
    }

    firebaseApp.analytics().logEvent("login", {
        method: "oauth2",
    });
}

export { firebaseApp, loginUsingExternalProvider }