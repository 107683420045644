import React, { useContext } from 'react'
import { Formik } from 'formik'
import { Link } from "react-router-dom"
import { useFetch } from "use-http"
import { AuthContext } from "../contexts/auth"

export default function ResetPassword({ className, description, onResetPassword = (email) => {} }) {
    const authContext = useContext(AuthContext)

    const { post, loading, error, response } = useFetch('/accounts/send-reset-password-link/', {
        cachePolicy: 'no-cache',
    })

    const onSubmit = async (values, { setSubmitting, setFieldError }) => {
        setSubmitting(true)

        const data = await post(values)
        if(!response.ok) {
            setFieldError('_', [data.error_description])
            setSubmitting(false)
        } else {
            onResetPassword && onResetPassword(values.email)
        }
    }

    const validate = (values) => {
        const errors = {};
        if (!values.email) {
            errors.email = 'The email address field is required.'
        } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
            errors.email = 'The email address field must be a valid email.'
        }

        return errors
    }

    return (
        <Formik
            initialValues={{ email: '', password: '' }}
            validate={validate}
            onSubmit={onSubmit}
            isInitialValid={false}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                isValid,
            }) => (
                    <form 
                        className={`reset-password form ${className || ''}`}
                        noValidate
                        onSubmit={handleSubmit}
                    >
                        <fieldset className="form__fieldset">
                            <div className="form__field">
                                <input
                                    className="form__input"
                                    type="text"
                                    placeholder="E-mail"
                                    id="email"
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                />
                            </div>

                            <div className="form__field__errors">
                                <div>{errors.email && touched.email && errors.email}</div>
                            </div>

                            {errors._ && errors._.map(error => (
                                <div className="form__field__errors" key={error}>
                                    <div>{error}</div>
                                </div>
                            ))}
                        </fieldset>

                        <fieldset className="form__fieldset">
                            <input
                                className="form__button button button-outline button-large"
                                type="submit"
                                value={isSubmitting ? "Submitting..." : "Log in"}
                                disabled={!isValid || isSubmitting}
                            />

                            {description && (
                                <div className="form__description">
                                    {description}
                                </div>
                            )}
                        </fieldset>
                    </form>
                )}
        </Formik>
    )
}