import React from 'react';
import Splash from '../layouts/Splash'
import Board from '../components/Board'
import { useQuery } from '../hooks'
import { useHistory } from "react-router-dom"
import VerifyUserForm from '../forms/VerifyUser'


export default function VerifyUser() {
    const history = useHistory()
    const query = useQuery()

    return (
        <Splash>
            <div className="join" slot="content">
                <div className="container">
                    <div className="join__wrapper row">
                        <div className="join__content column column-40">
                            <Board heading={"Verify User"} className="login">
                                <VerifyUserForm 
                                    userId={query.user_id}
                                    timestamp={query.timestamp}
                                    signature={query.signature}
                                    onVerifyUser={() => {
                                        alert('Your account has been verified successfully, you can now login using your credentials.')
                                        // FIXME: history.* doesn't work
                                        // history.replace('/login')
                                        window.location.href = '/login'
                                    }}
                                />
                            </Board>
                        </div>
                    </div>
                </div>
            </div>
        </Splash>
    )
}
