import React, { useContext } from 'react'
import Panel from './Panel'
import MobileApp from './MobileApp'
import Login from '../forms/Login'
import { Link } from "react-router-dom"
import { AuthContext } from "../contexts/auth"

export default function Sidebar ({ className }) {
    const authContext = useContext(AuthContext)

    return (
        <aside className={`sidebar ${className || ''}`}>
            <Panel variant="padded">
            {authContext.isLoggedIn  ? (
                <div>
                    You're conntected, welcome!
                </div>
            ) : (
                <Login 
                    description={(
                        <>
                        Sign in or <Link to="/register">register</Link> to
                    save<br />articles for later
                        </>
                    )} 
                />
            )}
            </Panel>

            <MobileApp className="is-themed" />

        </aside>
    )
}