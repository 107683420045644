import React, { useContext, useState } from 'react'
import { useParams } from "react-router-dom"
import { useFetch } from "use-http"
import ArticleFrame from '../components/ArticleFrame'
import { AuthContext }  from '../contexts/auth'

export default function Article() {
    const authContext = useContext(AuthContext)
    const { id } = useParams()
    const { 
        loading: loadingArticle, 
        error, 
        data: article
    } = useFetch(`/articles/${id}/`, {
        data: {},
        cachePolicy: 'no-cache',
    }, [id])
    const [articleSave, setArticleSave] = useState(article.saved_by_me)

    const saveArticleRequest = useFetch('/articles', {
        cachePolicy: 'no-cache',
    })

    const onArticleSaveChange = async (saved) => {
        await saveArticleRequest[saved ? 'post' : 'delete'](`/${id}/save/`)
        setArticleSave(saved)
    }

    return (
        <>
            <ArticleFrame 
                key={article.id}
                title={article.title}
                url={article.url}
                savedByMe={articleSave}
                savable={authContext.isLoggedIn}
                onSaveChange={onArticleSaveChange}
            />
        </>
    )
}