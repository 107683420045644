import React from 'react';
import Splash from '../layouts/Splash'
import Board from '../components/Board'
import VerifyPasswordResetForm from '../forms/VerifyPasswordReset'
import { Link, useHistory } from "react-router-dom"

export default function VerifyPasswordReset() {
    const history = useHistory()

    return (
        <Splash>
            <div className="join" slot="content">
                <div className="container">
                    <div className="join__wrapper row">
                        <div className="join__content column column-40">
                            <Board heading={"Sign In"} className="VerifyPasswordReset">
                                <VerifyPasswordResetForm 
                                    onVerifyPasswordReset={() => {
                                        alert(`Password has been reset successfully`)
                                        history.replace('/login')
                                    }}
                                />
                            </Board>
                        </div>
                    </div>
                </div>
            </div>
        </Splash>
    )
}
