import React, { useContext } from 'react'
import { Formik } from 'formik'
import { Link, useHistory } from "react-router-dom"
import { useFetch } from "use-http"
import { AuthContext } from "../contexts/auth"
import { ConfigContext } from "../contexts/config"
import { loginUsingExternalProvider } from "../services"

export default function Register({ className, description }) {
    const { config } = useContext(ConfigContext)
    const authContext = useContext(AuthContext)
    const history = useHistory()

    const { post, loading, error, response } = useFetch('/accounts/register/', {
        cachePolicy: 'no-cache',
    })

    const loginUsingProvider = useFetch('/auth/convert-token/', {
        cachePolicy: 'no-cache',
    })

    const onSubmit = async (values, { setSubmitting, setFieldError }) => {
        setSubmitting(true)

        const body = {...values}
        if(!values.username) delete body.username 

        const data = await post(body)
        if(!response.ok) {
            Object.entries(data).forEach(([field, errors]) => {
                setFieldError(field, errors.map(error => error.message))
            })
            setSubmitting(false)
        } else {
            alert(`A confirmation link has been sent to your email address ${values.email}`)
            history.replace('/')
        }
    }

    const validate = (values) => {
        const errors = {};
        if (!values.email) {
            errors.email = 'The email address field is required.'
        } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
            errors.email = 'The email address field must be a valid email.'
        }
        // if(values.username && !/^[\w.@+-]+$/i.test(values.username)) {
        //     errors.username = 'The username field can only contain characters, digits, and symbols (_ - . @).'
        // }

        if (!values.password) {
            errors.password = 'The password field is required.'
        }
        if (!values.passwordConfirm) {
            errors.passwordConfirm = 'The password confirmation field is required.'
        }

        if (values.password && values.passwordConfirm && values.password != values.passwordConfirm) {
            errors.password = 'Password confirmation does not match.'
        }

        return errors
    }

    const onLogin = () => {
        history.replace('/')
    }

    return (
        <Formik
            initialValues={{ email: '', password: '', passwordConfirm: '', username: '' }}
            validate={validate}
            onSubmit={onSubmit}
            isInitialValid={false}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setSubmitting,
                setFieldError,
                isValid,
            }) => (
                    <form 
                        className={`register form ${className || ''}`}
                        noValidate
                        onSubmit={handleSubmit}
                    >
                        <fieldset className="form__fieldset">
                            <div className="form__field">
                                <input
                                    className="form__input"
                                    type="text"
                                    placeholder="E-mail"
                                    id="email"
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                />
                            </div>

                            <div className="form__field__errors">
                                <div>{errors.email && touched.email && errors.email}</div>
                            </div>

                            {/* <div className="form__field">
                                <input
                                    className="form__input"
                                    type="text"
                                    placeholder="Username (optional)"
                                    id="username"
                                    name="username"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.username}
                                />
                            </div>

                            <div className="form__field__errors">
                                <div>{errors.username && touched.username && errors.username}</div>
                            </div> */}

                            {/* <div className="form__field">
                                <input
                                    className="form__input"
                                    type="text"
                                    placeholder="First Name"
                                    id="firstName"
                                    name="firstName"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.firstName}
                                />
                            </div>

                            <div className="form__field__errors">
                                <div>{errors.firstName && touched.firstName && errors.firstName}</div>
                            </div>

                            <div className="form__field">
                                <input
                                    className="form__input"
                                    type="text"
                                    placeholder="Last Name"
                                    id="lastName"
                                    name="lastName"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.lastName}
                                />
                            </div>

                            <div className="form__field__errors">
                                <div>{errors.lastName && touched.lastName && errors.lastName}</div>
                            </div> */}

                            <div className="form__field">
                                <input
                                    className="form__input"
                                    type="password"
                                    placeholder="Password"
                                    id="password"
                                    name="password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                />
                            </div>

                            <div className="form__field__errors">
                                <div>{errors.password && touched.password && errors.password}</div>
                            </div>


                            <div className="form__field">
                                <input
                                    className="form__input"
                                    type="password"
                                    placeholder="Confirm Password"
                                    id="passwordConfirm"
                                    name="passwordConfirm"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.passwordConfirm}
                                />
                            </div>

                            <div className="form__field__errors">
                                <div>{errors.passwordConfirm && touched.passwordConfirm && errors.passwordConfirm}</div>
                            </div>


                            {errors._ && errors._.map(error => (
                                <div className="form__field__errors" key={error}>
                                    <div>{error}</div>
                                </div>
                            ))}
                        </fieldset>

                        <fieldset className="form__fieldset">
                            <input
                                className="form__button button button-outline button-large"
                                type="submit"
                                value={isSubmitting ? "Submitting..." : "Sign up"}
                                disabled={!isValid || isSubmitting}
                            />


                            <input
                                className="form__button button button-facebook button-large"
                                type="button"
                                value="Sign up with Facebook"
                                onClick={() => loginUsingExternalProvider('facebook', { authContext, config, loginUsingProvider, onSuccess: onLogin, setSubmitting, setFieldError })}
                            />

                            <input
                                className="form__button button button-google button-large"
                                type="button"
                                value="Sign up with Google"
                                onClick={() => loginUsingExternalProvider('google-oauth2', { authContext, config, loginUsingProvider, onSuccess: onLogin, setSubmitting, setFieldError })}
                            />

                            <input
                                className="form__button button button-apple button-large"
                                type="button"
                                value="Sign up with Apple"
                                onClick={() => loginUsingExternalProvider('apple-id', { authContext, config, loginUsingProvider, onSuccess: onLogin, setSubmitting, setFieldError })}
                            />

                            <div className="form__description">
                                {description}
                            </div>
                        </fieldset>
                    </form>
                )}
        </Formik>
    )
}