import React, { useState, useEffect, useContext } from 'react'
import Panel from './Panel'
import Article from './Article'
import { useFetch } from "use-http"
import { snakeCase } from "change-case"
import useInterval from '@use-it/interval'
import { orderBy, uniqBy } from 'lodash'
import { Link } from "react-router-dom"
import { Spinner } from './Loader'
import './Articles.scss'
import config from '../config'

const buildArticlesUrlFromQuery = (query) => {
    const queryString = new URLSearchParams()

    Object.entries(query).forEach(([key, value]) => queryString.append(snakeCase(key), value))

    return `/articles/?${queryString}`
}

export default function Articles({
    query = {
        // savedByMe,
        // promoted,
        // popular,
        // createdAfter,
        // createdBefore,
    },
    autoRefresh = true,
    limit = 20,
    refreshByField = 'created',
    savable = true,
    className,
}) {
    const [articleSaveMap, setArticleSaveMap] = useState({})

    const [page, setPage] = useState({
        offset: 0,
        limit,
    })

    const [hasMorePages, setHasMorePages] = useState(true)

    const [pendingArticlesCount, setPendingArticlesCount] = useState(0)
    const [loadingPendingArticles, setLoadingPendingArticles] = useState(false)
    const [lastTimeLoadedPendingArticlesCount, setLastTimeLoadedPendingArticlesCount] = useState(new Date())

    const { 
        get: getPendingArticlesCount,
        abort: abortPendingArticlesCount,
    } = useFetch(buildArticlesUrlFromQuery({
        ...page,
        ...query,
        limit: 1,
        offset: 0,
        [`${refreshByField}After`]: lastTimeLoadedPendingArticlesCount.toISOString(),
    }), {
        cachePolicy: 'no-cache',
    }, [lastTimeLoadedPendingArticlesCount])

    useInterval(async () => {
        if(!lastTimeLoadedPendingArticlesCount) return 
        if(!autoRefresh) return

        const data = await getPendingArticlesCount()
        setPendingArticlesCount(data.count)
    }, 5000)

    const {
        loading: loadingArticles, 
        error: errorLoadingArticles, 
        data: { results: articles },
        abort: abortLoadingArticles,
    } = useFetch(buildArticlesUrlFromQuery({
        ...page,
        ...query,
    }), {
        onNewData: (currentData, newData) => {
            setLoadingPendingArticles(false)
            if(!loadingPendingArticles) {
                setHasMorePages(!!newData.next)
            }

            return {
                ...newData,
                results: orderBy(uniqBy([...currentData.results, ...newData.results], 'id'), [refreshByField], ['desc']),
            }
        },
        data: {
            next: null,
            previous: null,
            results: []
        },
        cachePolicy: 'no-cache',
        retries: 3,
    }, [page])

    useEffect(() => () => {
        abortPendingArticlesCount && abortPendingArticlesCount()
        abortLoadingArticles && abortLoadingArticles()
    }, [])

    const loadNextPage = () => {
        setPage({
            offset: page.offset + page.limit,
            limit: page.limit,
        })
    }

    const loadPendingArticles = () => {
        setLoadingPendingArticles(true)
        setPage({
            offset: 0,
            limit: pendingArticlesCount,
        })
        setLastTimeLoadedPendingArticlesCount(new Date())
        setPendingArticlesCount(0)
    }

    const saveArticleRequest = useFetch('/articles', {
        cachePolicy: 'no-cache',
    })

    const onArticleSaveChange = async (id, saved) => {
        await saveArticleRequest[saved ? 'post' : 'delete'](`/${id}/save/`)
        setArticleSaveMap((prevArticleSaveMap) => ({
            ...prevArticleSaveMap,
            [id]: saved,
        }))
    }

    const isRedirectablePublisher = (publisher) => {
        return config.ARTICLES.REDIRECTABLE_PUBLISHERS.indexOf(publisher.name) >= 0
    }

    const renderArticle = (article) => {
        const renderedArticle = (
            <Article
                title={article.title}
                publisher={article.publisher}
                created={article.created}
                images={article.images}
                savedByMe={articleSaveMap.hasOwnProperty(article.id) ? articleSaveMap[article.id] : article.saved_by_me}
                onSaveChange={(saved) => onArticleSaveChange(article.id, saved)}
                savable={savable}
            />
        )
        if(isRedirectablePublisher(article.publisher)) {
            return (
                <a key={`article-${article.id}`} href={article.url} target="_blank">
                    {renderedArticle}
                </a>
            )
        } else {
            return (
                <Link key={`article-${article.id}`} to={`/articles/${article.id}`} target="_blank">
                    {renderedArticle}
                </Link>
            )
        }
        
    }

    return (
        <div className={`media-list ${className || ''}`}>
            <div className="media-list__header">
                {(pendingArticlesCount > 0 || loadingPendingArticles) && (
                    <a className="media-list__new" onClick={loadPendingArticles}>
                        {loadingPendingArticles ? (
                            <>
                                loading
                                <br />...
                            </>
                        ) : (
                                <>
                                    Load New Ones ({pendingArticlesCount} More Articles)
                            <br />▾
                            </>
                        )}
                    </a>
                )}
            </div>
            {articles && articles.length == 0 && !loadingArticles && (
                <h2><center>no articles</center></h2>
            )} 
            <Panel className="media-list__items panel--themed">
                {articles && articles.map(renderArticle)}
            </Panel>

            {hasMorePages && (
                <div className="media-list__footer">
                    {loadingArticles && <Spinner />}
                    {!loadingArticles && (
                        <a className="media-list__more" onClick={loadNextPage}>
                            show more
                            <br />▾
                        </a>
                    )}
                </div>
            )}
        </div>
    )
}