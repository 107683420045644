import React from 'react'
import './Board.scss'
import Panel from './Panel'

export default function Board({ heading, children, className }) {
  return (
    <div className={`board ${className || ''}`}>
      <div className="board__header">
        <h2 name="heading" className="board__heading">
          {heading}
        </h2>
      </div>

      <Panel className="board__panel">
        {children}
      </Panel>
    </div>
  )
}