import React, { useContext } from 'react'
import { Formik } from 'formik'
import { Link } from "react-router-dom"
import { useFetch } from "use-http"
import { AuthContext } from "../contexts/auth"
import { useQuery } from '../hooks'


export default function VerifyPasswordReset({ className, description, onVerifyPasswordReset = (email) => {} }) {
    const authContext = useContext(AuthContext)
    const query = useQuery()

    const { post, loading, error, response } = useFetch('/accounts/reset-password/', {
        cachePolicy: 'no-cache',
    })

    const onSubmit = async (values, { setSubmitting, setFieldError }) => {
        setSubmitting(true)

        const data = await post(values)
        if(!response.ok) {
            setFieldError('_', [data.error_description])
            setSubmitting(false)
        } else {
            onVerifyPasswordReset && onVerifyPasswordReset(values.email)
        }
    }

    const validate = (values) => {
        const errors = {}
        if(!values.user_id || !values.timestamp || !values.signature) {
            errors._ = ['Invalid password reset link.']
        }

        if (!values.password) {
            errors.password = 'The password field is required.'
        }
        if (!values.passwordConfirm) {
            errors.passwordConfirm = 'The password confirmation field is required.'
        }

        if (values.password && values.passwordConfirm && values.password != values.passwordConfirm) {
            errors.password = 'Password confirmation does not match.'
        }

        return errors
    }

    return (
        <Formik
            initialValues={{ password: '', passwordConfirm: '', ...query }}
            validate={validate}
            onSubmit={onSubmit}
            isInitialValid={false}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                isValid,
            }) => (
                    <form 
                        className={`verify-password-reset form`}
                        noValidate
                        onSubmit={handleSubmit}
                    >
                        <input type="hidden" id="user_id" name="user_id" value={values.user_id} />
                        <input type="hidden" id="timestamp" name="timestamp" value={values.timestamp} />
                        <input type="hidden" id="signature" name="signature" value={values.signature} />

                        <fieldset className="form__fieldset">
                            <div className="form__field">
                                <input
                                    className="form__input"
                                    type="password"
                                    placeholder="Password"
                                    id="password"
                                    name="password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                />

                                <Link to="/reset-password">Forgot?</Link>
                            </div>

                            <div className="form__field__errors">
                                <div>{errors.password && touched.password && errors.password}</div>
                            </div>

                            <div className="form__field">
                                <input
                                    className="form__input"
                                    type="password"
                                    placeholder="Confirm Password"
                                    id="passwordConfirm"
                                    name="passwordConfirm"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.passwordConfirm}
                                />
                            </div>

                            <div className="form__field__errors">
                                <div>{errors.passwordConfirm && touched.passwordConfirm && errors.passwordConfirm}</div>
                            </div>
                            
                            {errors._ && errors._.map(error => (
                                <div className="form__field__errors" key={error}>
                                    <div>{error}</div>
                                </div>
                            ))}
                        </fieldset>

                        <fieldset className="form__fieldset">
                            <input
                                className="form__button button button-outline button-large"
                                type="submit"
                                value={isSubmitting ? "Submitting..." : "Reset Password"}
                                disabled={!isValid || isSubmitting}
                            />

                            {description && (
                                <div className="form__description">
                                    {description}
                                </div>
                            )}
                        </fieldset>
                    </form>
                )}
        </Formik>
    )
}