import React, { useContext, useCallback } from 'react'
import { Link } from "react-router-dom"
import Loader from './Loader'
import './Navbar.scss'
import './ArticleFrame.scss'
import BodyClassName from 'react-body-classname'

export default function ArticleFrame({ title, url, savedByMe, onSaveChange, savable, className }) {
    const handleSaveChange = useCallback((event) => {
        event.stopPropagation()
        event.nativeEvent.stopImmediatePropagation()

        onSaveChange(!savedByMe)
    }, [savedByMe])

    return (
        <BodyClassName className="full-screen">
            <div className={`article-frame ${className || ''}`}>
                <div className="article-frame__bar navbar">
                    <ul className="navbar__menu navbar__menu--left">
                        <li className="navbar__item navbar__item__title">
                            <a href={url} className="article-frame__title navbar__link">
                                <h6 className="article-frame__heading">{title}</h6>
                            </a>
                        </li>
                    </ul>

                    <ul className="navbar__menu navbar__menu--right">
                        <li className="navbar__item">
                            <a
                                className="navbar__redirect navbar__link"
                                href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${encodeURI(window.location.href)}&hashtags=GameFeed.io`}
                                target="_blank"
                            >
                                <i className="icon-twitter"></i>
                            </a>
                        </li>

                        <li className="navbar__item">
                            <a
                                className="navbar__redirect navbar__link"
                                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(window.location.href)}`}
                                target="_blank"
                            >
                                <i className="icon-facebook"></i>
                            </a>
                        </li>

                        {savable && (
                            <li className="navbar__item">
                                <a
                                    className="navbar__redirect navbar__link"
                                    href={"javascript:void(0)"}
                                    onClick={handleSaveChange}
                                >
                                    <i class={savedByMe ? 'icon-star-filled' : 'icon-star-empty'}></i>
                                </a>
                            </li>
                        )}

                        <li className="navbar__item">
                            <a className="navbar__redirect navbar__link" href={url}>
                                <i className="icon-share"></i>
                            </a>
                        </li>

                        <li className="navbar__item">
                            <Link to="/" className="navbar__redirect navbar__link">
                                <i className="icon-exit"></i>
                            </Link>
                        </li>
                    </ul>
                </div>

                <iframe src={url} className="article-frame__window" frameBorder={0}></iframe>

                <Loader
                    className="article-frame__loader"
                    messages="We could not show this article here. Please visit it manualy."
                    isActive
                />
            </div>
        </BodyClassName>
    )
}