import React, { useContext } from 'react'
import { Formik } from 'formik'
import { Link } from "react-router-dom"
import { useFetch } from "use-http"
import { AuthContext } from "../contexts/auth"
import { ConfigContext } from "../contexts/config"
import { loginUsingExternalProvider } from "../services"
import * as firebase from 'firebase' 

export default function Login({ className, description, onLogin = (authData) => { } }) {
    const { config } = useContext(ConfigContext)
    const authContext = useContext(AuthContext)

    const loginUsingPassword = useFetch('/auth/token/', {
        cachePolicy: 'no-cache',
    })

    const loginUsingProvider = useFetch('/auth/convert-token/', {
        cachePolicy: 'no-cache',
    })

    const onSubmit = async (values, { setSubmitting, setFieldError }) => {
        setSubmitting(true)

        const data = await loginUsingPassword.post({
            client_id: config.API.CLIENT_ID,
            client_secret: config.API.CLIENT_SECRET,
            grant_type: "password",
            username: values.email,
            password: values.password
        })
        if (!loginUsingPassword.response.ok) {
            setFieldError('_', [data.error_description])
            setSubmitting(false)
        } else {
            authContext.login(data.access_token, data.expires_in)
            onLogin && onLogin(data)
        }
    }

    const validate = (values) => {
        const errors = {};
        if (!values.email) {
            errors.email = 'The email address field is required.'
        } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
            errors.email = 'The email address field must be a valid email.'
        }
        if (!values.password) {
            errors.password = 'The password field is required.'
        }

        return errors
    }

    return (
        <Formik
            initialValues={{ email: '', password: '' }}
            validate={validate}
            onSubmit={onSubmit}
            isInitialValid={false}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setSubmitting,
                setFieldError,
                isValid,
            }) => (
                    <form
                        className={`login form ${className || ''}`}
                        noValidate
                        onSubmit={handleSubmit}
                    >
                        <fieldset className="form__fieldset">
                            <div className="form__field">
                                <input
                                    className="form__input"
                                    type="text"
                                    placeholder="E-mail"
                                    id="email"
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                />
                            </div>

                            <div className="form__field__errors">
                                <div>{errors.email && touched.email && errors.email}</div>
                            </div>

                            <div className="form__field">
                                <input
                                    className="form__input"
                                    type="password"
                                    placeholder="Password"
                                    id="password"
                                    name="password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                />

                                <Link to="/reset-password">Forgot?</Link>
                            </div>

                            <div className="form__field__errors">
                                <div>{errors.password && touched.password && errors.password}</div>
                            </div>
                            {errors._ && errors._.map(error => (
                                <div className="form__field__errors" key={error}>
                                    <div>{error}</div>
                                </div>
                            ))}
                        </fieldset>

                        <fieldset className="form__fieldset">
                            <input
                                className="form__button button button-outline button-large"
                                type="submit"
                                value={isSubmitting ? "Submitting..." : "Log in"}
                                disabled={!isValid || isSubmitting}
                            />

                            <input
                                className="form__button button button-facebook button-large"
                                type="button"
                                value="Log in with Facebook"
                                onClick={() => loginUsingExternalProvider('facebook', { authContext, config, loginUsingProvider, onSuccess: onLogin, setSubmitting, setFieldError })}
                            />

                            <input
                                className="form__button button button-google button-large"
                                type="button"
                                value="Log in with Google"
                                onClick={() => loginUsingExternalProvider('google-oauth2', { authContext, config, loginUsingProvider, onSuccess: onLogin, setSubmitting, setFieldError })}
                            />

                            <input
                                className="form__button button button-apple button-large"
                                type="button"
                                value="Log in with Apple"
                                onClick={() => loginUsingExternalProvider('apple-id', { authContext, config, loginUsingProvider, onSuccess: onLogin, setSubmitting, setFieldError })}
                            />

                            <div className="form__description">
                                {description}
                            </div>
                        </fieldset>
                    </form>
                )}
        </Formik>
    )
}