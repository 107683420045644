import React, { useContext } from 'react'
import { ThemeContext } from '../contexts/theme'
import './Panel.scss'

export default function Panel ({ children, variant = 'standard', className }) {
  const { theme } = useContext(ThemeContext)

  return (
    <div class={`panel is-themed panel--${theme} panel--${variant} ${className || ''}`}>{children}</div>
  )
}