import React, { useContext } from 'react';
import Page from '../layouts/Page'
import Articles from '../components/Articles'
import { AuthContext } from '../contexts/auth'

export default function Home () {
    const authContext = useContext(AuthContext)

    return (
        <Page>
            <Articles savable={authContext.isLoggedIn} />
        </Page>
    )
}
