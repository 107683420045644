import React from 'react'
import MobileAppMockupImage from '../assets/mobile-app-mockup.png'
import './MobileApp.scss'

export default function MobileApp({ className }) {
  return (
    <div className={`mobile-app is-themed ${className || ''}`}>
      <h4 className="mobile-app__heading">Download Free App</h4>

      <img className="mobile-app__image" src={MobileAppMockupImage} alt="GameFeed Mobile App" />

      <a
        className="mobile-app__button button button-outline"
        href="https://apps.apple.com/us/app/gamefeed-app/id961293765"
        rel="noopener noreferrer"
        target="_blank"
      >Download for iOS</a>
    </div>
  )
}