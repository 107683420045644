import React from 'react';
import Splash from '../layouts/Splash'
import Board from '../components/Board'
import ResetPasswordForm from '../forms/ResetPassword'
import { Link, useHistory } from "react-router-dom"

export default function ResetPassword() {
    const history = useHistory()

    return (
        <Splash>
            <div className="join" slot="content">
                <div className="container">
                    <div className="join__wrapper row">
                        <div className="join__content column column-40">
                            <Board heading={"Forgot your password? Can’t sign in?"} className="ResetPassword">
                                <ResetPasswordForm 
                                    onResetPassword={(email) => {
                                        alert(`A confirmation link has been sent to your email address ${email}`)
                                        history.replace('/')
                                    }}
                                />
                            </Board>
                        </div>
                    </div>
                </div>
            </div>
        </Splash>
    )
}
