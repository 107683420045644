import React, { useContext, useState } from 'react'
import {
    Link,
    useLocation,
    useRouteMatch,
} from 'react-router-dom'
import './Navbar.scss'
import GameFeedLogo from '../assets/gamefeed-logotype.png'
import { AuthContext } from "../contexts/auth"
import { ThemeContext } from "../contexts/theme"


export default function Navbar({ variant, className }) {
    const routeLocation = useLocation()
    const routeMatch = useRouteMatch(routeLocation.pathname)
    const authContext = useContext(AuthContext)
    const { toggleTheme } = useContext(ThemeContext)
    const [isOverlayMenuVisible, setIsOverlayMenuVisible] = useState(false)

    let currentArticlesPageName = null

    switch (routeMatch.path) {
        case '/promoted':
            currentArticlesPageName = 'Promoted'
            break
        case '/popular':
            currentArticlesPageName = 'Popular'
            break
        default:
            currentArticlesPageName = 'All'
            break
    }

    const toggleOverlayMenu = () => {
        setIsOverlayMenuVisible((isVisible) => !isVisible)
    }

    return (
        <div>
            <nav class={`navbar navbar--${variant} ${className || ''}`}>
                <ul className="navbar__menu navbar__menu--left">
                    <li className="navbar__menu__item">
                        <Link to="/" className="navbar__logotype navbar__link">
                            <img src={GameFeedLogo} />
                            <span className="hidden__xs">Gamefeed</span>
                        </Link>
                    </li>
                    <li className="navbar__menu__item hidden__xs">
                        <div className="navbar__filters navbar__link">
                            <i className="icon-books"></i>
                            <span className="hidden__xs">
                                <span>{currentArticlesPageName}</span>
                                <span style={{ float: "right" }}>▾</span>
                            </span>
                            <ul className="navbar__submenu">
                                <li className="navbar__submenu__item hidden__xs">
                                    <Link to="/" className="navbar__link">
                                        <span class>All</span>
                                    </Link>
                                </li>
                                <li className="navbar__submenu__item hidden__xs">
                                    <Link to="popular" className="navbar__link">
                                        <span class>Popular</span>
                                    </Link>
                                </li>
                                <li className="navbar__submenu__item hidden__xs">
                                    <Link to="promoted" className="navbar__link">
                                        <span class>Promoted</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
                <ul className="navbar__menu navbar__menu--right">
                    {authContext.isLoggedIn && (
                        <li className="navbar__menu__item hidden__xs">
                            <Link to="/saved" className="navbar__link">
                                <i className="icon-star-full" style={{marginRight: '1rem'}}></i>{' '}
                                <span class>Favourites</span>
                            </Link>
                        </li>
                    )}
                    <li className="navbar__menu__item theme-toggle-icon">
                        <a className="navbar__link" onClick={toggleTheme}>
                            <i className="icon-brightness-contrast"></i>
                        </a>
                    </li>
                    <li className="navbar__menu__item hidden__xs">
                        {authContext.isLoggedIn ? (
                            <a className="navbar__link" onClick={authContext.logout}>Sign out</a>
                        ) : (
                            <Link to="/login" className="navbar__link">Sign in</Link>
                        )}
                    </li>
                    <li className="navbar__menu__item hidden__xl">
                        <a className="navbar__link" onClick={toggleOverlayMenu}>
                            <i className="icon-stack"></i>
                        </a>
                    </li>
                </ul>
            </nav>
            {isOverlayMenuVisible && (
                <div className="nav-overlay" onClick={toggleOverlayMenu}>
                    <ul>
                        <li>
                            <Link to="/" className="white">ALL</Link>
                        </li>
                        <li>
                            <Link to="/popular" className="white">Popular</Link>
                        </li>
                        <li>
                            <Link to="/promoted" className="white">Promoted</Link>
                        </li>


                        {authContext.isLoggedIn && (
                            <>
                                <hr />
                                <li>
                                    <Link to="/saved" className="white">Favourites</Link>
                                </li>
                            </>
                        )}

                        <hr />

                        {authContext.isLoggedIn ? (
                            <li>
                                <a className="white" onClick={() => authContext.logout()}>Sign out</a>
                            </li>
                        ) : (
                            <li>
                                <Link to="/login" className="white">Sign in</Link>
                            </li>
                        )}
                    </ul>
                </div>
            )}
        </div>
    )
}