import React, { useCallback } from 'react'
import Moment from 'react-moment'
import './Article.scss'

export default function Article({ id, title, images, savedByMe, created, publisher, onSaveChange, savable, className }) {
    const handleSaveChange = useCallback((event) => {
        event.stopPropagation()
        event.nativeEvent.stopImmediatePropagation()

        onSaveChange(!savedByMe)
    }, [savedByMe])

    return (
        <article className={`media ${className || ''}`} key={`article-${id}`}>
            <div
                className="media__image"
                style={{
                    backgroundImage: images.map((image) => `url('${image.replicate_url}'), url('${image.source_url}')`).join(','),
                }}
            />
            <div className="media__body">
                <h4 className="media__title">{title}</h4>

                {savable && (
                    <div className="media__star" >
                        <a href={"javascript:void(0)"} onClick={handleSaveChange}><i className={`icon-star-${savedByMe ? 'full' : 'empty'}`}></i></a>
                    </div>
                )}

                <div className="media__details">
                    <Moment fromNow>{created}</Moment>

                    <span>{publisher.name}</span>
                </div>
            </div>
        </article>
    )
}

