import React, { useContext } from 'react';
import Page from '../layouts/Page'
import Articles from '../components/Articles'
import { AuthContext } from '../contexts/auth'


export default function Promoted () {
    const authContext = useContext(AuthContext)

    return (
        <Page>
            <Articles 
                query={{
                    promoted: true,
                    ordering: '-promoted',
                }} 
                refreshByField='promoted'
                savable={authContext.isLoggedIn}
            />
        </Page>
    )
}
