import React, { useEffect, useState } from 'react';
import Loader from '../components/Loader'
import { useFetch } from "use-http"

export default function VerifyUser({ className, userId, timestamp, signature, onVerifyUser = () => {} }) {
    const [message, setMessage] = useState()

    const { post, loading, error, response } = useFetch('/accounts/verify-registration/', {
        cachePolicy: 'no-cache',
    })

    useEffect(() => {
        const verifyUser = async () => {
            const data = await post({
                user_id: userId, 
                timestamp, 
                signature,
            })
            if(data) {
                if(response.ok){
                    onVerifyUser && onVerifyUser()
                } else {
                    setMessage(data.message)
                }
            }
        }

        verifyUser()
    }, [userId, timestamp, signature])

    return (
        <>
            <Loader isActive={loading} center={false} className={className} />
            {message && (
                <h3>
                error: {message}
                </h3>
            )}
        </>
    )
}