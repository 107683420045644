import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import './Page.scss'

export default function Page({ children, className }) {

    return (
        <div className={`page ${className || ''}`}>
            <Navbar className="page__navbar" />

            <div className="page__container container">
            <div className="page__wrapper row">
                <div className="page__content column column-75 is-themed">
                {children}
                </div>

                <Sidebar className="page__sidebar column column-25" />
            </div>
            </div>

            <Footer className="page__footer is-themed" />
        </div>
    )
}