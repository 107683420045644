

export default {
    API: {
        BASE_URL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000',
        CLIENT_ID: process.env.REACT_APP_API_CLIENT_ID || '0000',
        CLIENT_SECRET: process.env.REACT_APP_API_CLIENT_SECRET || '0000',
    },
    ARTICLES: {
        REDIRECTABLE_PUBLISHERS: (
            process.env.REACT_APP_ARTICLES_REDIRECTABLE_PUBLISHERS && 
            process.env.REACT_APP_ARTICLES_REDIRECTABLE_PUBLISHERS.split(',')
         ) || [
            "Polygon",
            "Reddit",
            "Ars Technica",
            "GameInformer",
            "Kotaku",
            "Engadget",
        ],
    },
}