import React from 'react';
import Page from '../layouts/Page'

export default function NotFound () {

    return (
        <Page>
            <h3>404 Not Found</h3>
        </Page>
    )
}
