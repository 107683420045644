import React from 'react';
import Splash from '../layouts/Splash'
import Board from '../components/Board'
import Register from '../forms/Register'
import { Link } from "react-router-dom"

export default function Home() {
    return (
        <Splash>
            <div className="join" slot="content">
                <div className="container">
                    <div className="join__wrapper row">
                        <div className="join__content column column-40">
                            <Board heading={"Create an account"} className="login">
                                <Register 
                                    description={<Link to="/login">I have an account</Link>}
                                />
                            </Board>
                        </div>
                    </div>
                </div>
            </div>
        </Splash>
    )
}
