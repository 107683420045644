import { useState, useCallback } from 'react'
import { useLocation } from 'react-router-dom'

export function useForceUpdate() {
  const [, setTick] = useState(0);
  const update = useCallback(() => {
    setTick(tick => tick + 1);
  }, [])
  return update;
}

export function useQuery() {
  return Object.fromEntries(new URLSearchParams(useLocation().search).entries())
}