import React, { useContext } from 'react';
import Page from '../layouts/Page'
import Articles from '../components/Articles'
import { AuthContext }  from '../contexts/auth'
import { Spinner } from '../components/Loader'


export default function Saved () {
    const authContext = useContext(AuthContext)

    return (
        <Page>
            {authContext.isLoggedIn ? (
                <Articles 
                    query={{
                        savedByMe: true,
                    }} 
                    autoRefresh={false}
                />
            ) : (
                <Spinner />
            )}
        </Page>
    )
}
