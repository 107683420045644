import React from 'react';
import Splash from '../layouts/Splash'
import Board from '../components/Board'
import LoginForm from '../forms/Login'
import { Link, useHistory } from "react-router-dom"

export default function Login() {
    const history = useHistory()

    return (
        <Splash>
            <div className="join" slot="content">
                <div className="container">
                    <div className="join__wrapper row">
                        <div className="join__content column column-40">
                            <Board heading={"Sign In"} className="login">
                                <LoginForm 
                                    description={<Link to="/register">I don’t have an account</Link>}
                                    onLogin={() => {
                                        history.replace('/')
                                    }}
                                />
                            </Board>
                        </div>
                    </div>
                </div>
            </div>
        </Splash>
    )
}
