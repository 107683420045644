import React from 'react'
import './Loader.scss'

export function Spinner() {
    return (
        <div className="spinner">
            <div className="cube1 cube"></div>
            <div className="cube2 cube"></div>
            <div className="cube4 cube"></div>
            <div className="cube3 cube"></div>
        </div>
    )
}


export default function Loader({ isActive, message, center = true, className }) {
    return (
        <div class={`loader ${center ? 'loader__center' : ''} ${className || ''}`}>
            {isActive && <h6 className="loader__message">{message}</h6>}

            <div className="loader__spinner">
                {isActive && <Spinner />}
            </div>
        </div>
    )
}