import React, { useContext } from 'react';
import Page from '../layouts/Page'
import Articles from '../components/Articles'
import moment from 'moment'
import { AuthContext } from '../contexts/auth'

export default function Popular () {
    const authContext = useContext(AuthContext)

    return (
        <Page>
            <Articles 
                query={{
                    popular: true,
                    createdAfter: moment().subtract(1, 'week').toISOString(),
                    ordering: '-views_count',
                }} 
                limit={50}
                autoRefresh={false}
                savable={authContext.isLoggedIn}
            />
        </Page>
    )
}
