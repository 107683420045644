import React from 'react'

export const Theme = Object.freeze({
    Light: 'light',
    Dark: 'dark',
})
  
export const ThemeContext = React.createContext({
    theme: Theme.Light,
    toggleTheme: () => {}
})